*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  height: 100vh;
  width: 100vw;
  padding: 1rem;
  display: flex;
  overflow: auto;
  color: #bfad0d;
  background: linear-gradient(135deg, #f0daa0, #aef1b0);
  align-items: flex-start;
  justify-content: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    sans-serif;
}

header {
  width: 100%;
  height: 4rem;
  display: flex;
  padding: 0.5rem;
  align-items: center;
  background: #202426;
  box-shadow: 1px 1px 13px #03030390;
  border-radius: 0.75rem;
  animation: enter 600ms ease;
  justify-content: space-evenly;
}

h3 {
  margin: auto;
  color: #bfad0d;
}

h4 {
  margin: auto;
  color: #ffa200;
  ;
}

button {
  border: none;
  outline: none;
  padding: 0.5rem;
  border-radius: 3px;
  margin: auto;
}

.grid {
  gap: 1.25rem;
  display: grid;
  margin: 3rem auto;
  grid-template-columns: repeat(4, auto);
}

.card {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background: transparent;
  width: 150px;
  animation: enter 600ms ease;
  border-radius: 0.75rem;
}

.card img {
  width: 100%;
}

.card-face,
.card-back {
  background: #202426;
  box-shadow: 1px 1px 13px #03030390;
  border-radius: 0.75rem;
  transition: all ease-in 200ms;
}

.card-face {
  position: absolute;
  transform: rotateY(90deg);
}

.card-back {
  padding: 0.5rem;
  transform: rotateY(0deg);
  transition-delay: 200ms;
}

.selected .card-face {
  transform: rotateY(0deg);
  transition-delay: 200ms;
}

.selected .card-back {
  transform: rotateY(90deg);
  transition-delay: 0s;
}

@keyframes enter {
  from {
    opacity: 0;
    transform: scale(0.5);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@media screen and (max-width: 720px) {
  body {
    align-items: center;
  }

  header {
    left: 0;
    bottom: 0;
    margin: auto;
    position: fixed;
    border-radius: 0;
  }

  .grid {
    margin: auto auto 3rem auto;
  }

  .card {
    width: 20vw;
  }
}